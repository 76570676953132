import Swiper, { Navigation, Pagination } from 'swiper';

import "swiper/swiper-bundle.css"
import './wall_component.sass'

Swiper.use([Navigation, Pagination]);
export default class Wall{
  constructor(el){
    this.el = el;
    this.initSwiper();
  }

  initSwiper(){
    const swiperEl = this.el.querySelector(".swiper-container-mobile");
    this.swiperMobile = new Swiper(swiperEl, {
      loop: false
    });

    const swiperElDesktop = this.el.querySelector(".swiper-container-desktop");
    this.swiperDesktop = new Swiper(swiperElDesktop, {
      loop: false
    });
  }
}


document.addEventListener("turbolinks:load", function() {
  const wallEl = document.querySelector(".wall")
  if(wallEl) new Wall(wallEl);
})