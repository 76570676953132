// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("trix")
require("@rails/actiontext")

require("bootstrap")

import "../components"
import "../stylesheets/application"
import "../stylesheets/admin"

import { Chart, registerables } from 'chart.js';
import { CHART_COLORS } from 'charts-js/utils'
import { namedColor } from 'charts-js/utils'

Chart.register(...registerables);

document.addEventListener("turbolinks:load", function() {
  $(function () {
    $('[data-toggle="tooltip"]').tooltip()
    $('[data-toggle="popover"]').popover()
  })
  if(document.getElementById('tideLevelsChart')){
    loadTideLevelData()
  }
})


function loadTideLevelData(){
  var el = document.getElementById('tideLevelsChart')
  var url = el.dataset.url
  $.getJSON(url).done(function(response) {
    setupTideLevelGraph(response)
  });
}

function setupTideLevelGraph(response){
  var ctx = document.getElementById('tideLevelsChart').getContext('2d');
  return new Chart(ctx, {
    type: 'line',
    data: generateGraphData(response),
    options: {
      animation: false,
    }
  });
}

function generateGraphData(response) {
  var dataset = []
  var dates = []
  for (let index = 0; index < response.data.length; index++) {
    var element = response.data[index]
    var object = {
      label: element.label,
      data: element.tide_level,
      borderWidth: 1,
      //borderColor: namedColor(index)
      // borderColor: function(context) {
      //   const chart = context.chart;
      //   const {ctx, chartArea} = chart;

      //   if (!chartArea) {
      //     // This case happens on initial chart load
      //     return null;
      //   }
      //   return getGradient(ctx, chartArea);
      // },
    }
    dates.push(element.date)
    dataset.push(object)
  }
  return {
    labels: response.dates,
    datasets: dataset
  }
}


let width, height, gradient;
function getGradient(ctx, chartArea) {
  const chartWidth = chartArea.right - chartArea.left;
  const chartHeight = chartArea.bottom - chartArea.top;
  if (gradient === null || width !== chartWidth || height !== chartHeight) {
    // Create the gradient because this is either the first render
    // or the size of the chart has changed
    width = chartWidth;
    height = chartHeight;
    gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
    gradient.addColorStop(0, CHART_COLORS.blue);
    gradient.addColorStop(0.5, CHART_COLORS.yellow);
    gradient.addColorStop(1, CHART_COLORS.red);
  }

  return gradient;
}
